<template>
  <base-card>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="parkedOrderList"
        :items-per-page="paginationLimit"
        hide-default-footer
        class="eg-table-bnone eg-table-td-py-md mt-2 px-4"
      >
        <template v-slot:item.action="{item}">
          <div class="d-flex justify-start">
            <v-btn
              class="ma-1"
              small
              color="primary"
              @click.native="onUnpark(item)"
            >
              {{ $t('order.unpark') }}
            </v-btn>

            <v-btn
              class="ma-1"
              small
              color="error"
              @click.native="onTerminate(item)"
            >
              {{ $t('order.terminate') }}
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        color="primary"
        :total-visible="7"
        circle
      />
    </v-card-actions>
  </base-card>
</template>

<script>
  import { mapActions } from 'vuex'

  import util from '@/utils'
  import { showErrorDialog } from '@/utils/swalDialog'

  export default {
    metaInfo: {
      title: 'Parked Orders',
    },

    data () {
      return {
        parkedOrderList: [],
        paginationLimit: 10,
        currentPage: 1,
        totalPages: 0,
        headers: [{
          text: this.$t('order.orderNumber'),
          value: 'provisioningSystemOrderNumber',
          width: 180,
        }, {
          text: this.$t('price.customerName'),
          value: 'customerName',
          width: 160,
        }, {
          text: this.$t('common.phoneNumber'),
          value: 'customerMobilePhone',
          width: 140,
        }, {
          text: this.$t('common.email'),
          value: 'customerEmail',
          width: 160,
        }, {
          text: this.$t('network.serviceName'),
          value: 'commercialService',
          width: 240,
        }, {
          text: this.$t('address.address'),
          value: 'fullAddress',
          width: 240,
        }, {
          text: this.$t('common.action'),
          value: 'action',
          width: 100,
        }],
      }
    },

    watch: {
      currentPage (val) {
        this.currentPage = val
        this.loadOrderList()
      },
    },

    mounted () {
      this.loadOrderList()
    },

    methods: {
      ...mapActions({
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
        fetchParkedZitiusOrders: 'fetchParkedZitiusOrders',
      }),

      async loadOrderList () {
        const query = {
          offset: (this.currentPage - 1) * this.paginationLimit,
          limit: this.paginationLimit,
        }

        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        this.setLoading(true)
        try {
          const response = await this.fetchParkedZitiusOrders(query)

          this.totalPages = Math.ceil(response.count / this.paginationLimit)
          this.parkedOrderList = response.rows
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      onUnpark (item) {
        console.log(item)
      },

      onTerminate (item) {
        console.log(item)
      },
    },
  }
</script>
